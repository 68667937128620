import firebase from "firebase/compat/app";

/* eslint-disable-next-line */
export interface HeaderProps {}

export function Header(props: HeaderProps) {
  return (
    <nav className="fixed top-0 z-30 w-full text-black bg-white">
      <div className="container flex flex-wrap items-center justify-between w-full px-4 py-3 mx-auto mt-0">
        <a
          href="/"
          className="flex items-center pl-4 no-underline hover:no-underline"
        >
          <img className="w-12 h-12" src="/armoiriebf.png" alt="armoiriebf" />
          <span
            id="burkinaFasoLink"
            className="hidden pl-2 text-2xl font-bold no-underline toggleColour hover:no-underline lg:text-4xl sm:block"
          >
            Burkina Faso
          </span>
        </a>
        <div className="flex flex-col space-y-2">
          <a
            className="flex items-center space-x-4 no-underline hover:no-underline"
            href="https://kreezus.com"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="w-16 h-16"
              src="/kreezus-logo.png"
              alt="kreezus-logo"
            />
            <div className="flex flex-col">
              <span className="text-2xl font-bold text-center text-gray-700 uppercase lg:text-4xl">
                Kreezus
              </span>
              <span className="hidden text-base font-medium text-gray-500 sm:block">
                Expert en digitalisation des systèmes
              </span>
            </div>
          </a>
          <button
            type="button"
            className="px-2 py-1 text-xs font-semibold text-gray-900 transition-colors duration-500 bg-white rounded shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-300"
            onClick={() => firebase.auth().signOut()}
          >
            Se déconnecter
          </button>
        </div>
      </div>
      <hr className="py-0 my-0 border-b border-gray-100 opacity-25" />
    </nav>
  );
}

export default Header;
