/* eslint-disable-next-line */
export interface FooterProps {}

export function Footer(props: FooterProps) {
  return (
    <footer className="mt-10 show-on-scroll">
      <div className="container flex flex-col items-center px-1 m-auto mx-auto lg:px-8">
        <div className="flex items-center py-1 mx-auto space-x-2">
          <span className="font-medium text-gray-600">Ce site a été réalisé par</span>
          <a className="block text-lg font-bold text-center text-gray-900 no-underline hover:no-underline hover:text-lime-900"
            href="https://kreezus.com/" target="_blank">Kreezus</a>
          <span className="font-semibold text-gray-700">- Expert en digital</span>
        </div>
        <div className="flex items-center py-1 mx-auto space-x-2 text-sm text-gray-700">
          <a className="text-lg font-bold text-center text-gray-800 no-underline hover:no-underline hover:text-slate-800"
            href="https://kreezus.com/" target="_blank">Kreezus</a> <span className="ml-4">c'est une équipe constituée de
            jeunes Burkinabè avec une expertise internationale</span>
        </div>
        <div className="flex items-center py-1 mx-auto space-x-2 text-sm text-gray-700">
          <span>Ouagadougou, Burkina Faso</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
