import { db } from "../../firebase";
import { useEffect, useState } from "react";
import SiteInfo from "../../components/SiteInfo";

function SiteStatus() {
  const [sites, setSites] = useState<any[] | undefined>();

  useEffect(() => {
    db.collection("websites")
      .get()
      .then((snapshot) => {
        const urlsRetrieved = snapshot.docs.map((snap) => snap.data()["url"]);
        setSites(urlsRetrieved);
      });
  }, []);
  return (
    <div className="w-full ">
      <div className="flex flex-col w-full mt-8">
        <div className="inline-block w-full min-w-full py-2 align-middle">
          <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
            {sites &&
              sites?.map((site) => (
                <li
                  key={site}
                  className="overflow-hidden border border-gray-200 rounded-xl"
                >
                  <SiteInfo url={site} />
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SiteStatus;
