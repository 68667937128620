import Root from "./routes/Root";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import ErrorPage from "./components/ErrorPage";
import SiteStatus from "./routes/SiteStatus";
import Profile from "./routes/Profile";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Root />} errorElement={<ErrorPage />}>
          {/* <Route path="dashboard" element={<Dashboard />} /> */}
          <Route path="site-status" element={<SiteStatus />} />
          <Route path="profile" element={<Profile />} />
          <Route path="" element={<Navigate to="site-status" />} />
        </Route>
        <Route path="*" element={<Navigate to="site-status" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
