import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { auth } from "../../firebase";
import StyledFirebaseAuth from "../../components/StyledFirebaseAuth";

import {} from "@heroicons/react/24/outline";
import Header from "../../components/Header/Header";
import MainInfoSection from "../../components/MainInfoSection/MainInfoSection";
import Footer from "../../components/Footer/Footer";

export interface LayoutProps {
  dummy?: string;
}

function Root(props: LayoutProps) {
  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: false,
        disableSignUp: {
          status: true,
          adminEmail: "rachide.ouattara@kreezus.com",
        },
      },
    ],
    adminRestrictedOperation: {
      status: true,
      adminEmail: "contact@kreezus.com",
    },
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,
    },
  };
  const [isSignedIn, setIsSignedIn] = useState(auth.currentUser != null); // Local signed-in state.

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        setIsSignedIn(!!user);
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);
  if (!isSignedIn) {
    return (
      <div className="flex flex-col justify-center min-h-full py-12 sm:px-6 lg:px-8">
        <div className="justify-center sm:mx-auto sm:w-full sm:max-w-md">
          <div className="flex justify-center">
            {/* <img
              className="w-auto h-16"
              src={yengaLogoTransparent}
              alt="Yenga"
            /> */}
          </div>
          <h2 className="mt-6 text-3xl font-bold tracking-tight text-center text-gray-900">
            Accédez à la page de status
          </h2>
        </div>
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={auth}
          className="mt-8 sm:mx-auto sm:w-full sm:max-w-md"
        />
      </div>
    );
  }
  return (
    <>
      {/* header section */}
      <section className="py-10">
        <div className="container">
          <Header></Header>
        </div>
      </section>
      <section className="bg-gray-100 py-14">
        <MainInfoSection></MainInfoSection>
      </section>
      <div className="container mx-auto">
        <Outlet />
      </div>
      {/* Footer */}
      <footer className="bg-white">
        <Footer></Footer>
      </footer>
      {/*
      This example requires updating your template:

      ```
      <html class="h-full bg-gray-50">
      <body class="h-full overflow-hidden">
      ```
    */}
    </>
  );
}

export default Root;
