/* eslint-disable-next-line */
export interface MainInfoSectionProps {}

export function MainInfoSection(props: MainInfoSectionProps) {
  return (
    <div className="container mx-auto">
      <p className="text-lg font-bold text-gray-700">
        Status des sites en ligne
      </p>
      <p className="text-base">
        Ce site renseigne sur l'accessibilité et fait l'état des lieux des sites
        et services digitaux du Burkina Faso
      </p>
    </div>
  );
}

export default MainInfoSection;
