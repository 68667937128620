import ReactCountryFlag from "react-country-flag";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { useCallback, useEffect, useState } from "react";
import { app } from "../../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";

/* eslint-disable-next-line */
export interface SiteInfoProps {
  url: string;
}

export function SiteInfo(props: SiteInfoProps) {
  const [siteInfo, setSiteInfo] = useState<any | undefined>();
  const getEnrichedDatas = useCallback(
    async (url: string) => {
      const functions = getFunctions(app, "europe-west1");
      const enricherFn = httpsCallable(functions, "enricher");
      const enrichedData = await enricherFn({ url: props.url });
      return enrichedData;
    },
    [props.url]
  );
  useEffect(() => {
    getEnrichedDatas(props.url).then((res) => {
      setSiteInfo(res.data as any);
    });
  }, [getEnrichedDatas, props.url]);
  if (!siteInfo) {
    return (
      <div className="p-6 m-4 mx-auto bg-gray-50">
        <svg className="w-10 h-10 text-red-500 animate-spin" viewBox="0 0 24 24"></svg>
        <p>Chargement de {props.url} ...</p>
      </div>
    );
  }
  return (
    <>
      <div className="flex items-center p-6 border-b gap-x-4 border-gray-900/5 bg-gray-50">
        <div className="text-sm font-medium leading-6 text-gray-900">
          <a
            href={`https://${props.url}`}
            target="_blank"
            rel="noreferrer"
            className="underline uppercase"
          >
            {props.url}
          </a>
        </div>
      </div>
      {siteInfo && (
        <dl className="px-6 py-4 -my-3 text-sm leading-6 divide-y divide-gray-100">
          {siteInfo.timeout && (
            <div className="p-4 border-l-4 border-red-400 bg-red-50">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon
                    className="w-5 h-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-700">
                    Timeout (délai dépassé)
                  </p>
                </div>
              </div>
            </div>
          )}
          {!siteInfo.timeout && (
            <>
              <div className="flex justify-between py-3 gap-x-4">
                <dt className="text-gray-500">Ip Adresse</dt>
                <dd className="text-gray-700">{siteInfo.ipAdress}</dd>
              </div>
              <div className="flex justify-between py-3 gap-x-4">
                <dt className="text-gray-500">Fournisseur</dt>
                <dd className="text-gray-700">{siteInfo.locationInfo.isp}</dd>
              </div>
              <div className="flex justify-between py-3 gap-x-4">
                <dt className="text-gray-500">Pays</dt>
                <dd className="text-gray-700">
                  <ReactCountryFlag
                    className="mr-5"
                    svg
                    countryCode={siteInfo.locationInfo.country_code2}
                    style={{
                      width: "2em",
                      height: "2em",
                    }}
                    title={siteInfo.locationInfo.country_name}
                  />
                  <span>{siteInfo.locationInfo.country_name}</span>
                </dd>
              </div>
              <div className="flex justify-between py-3 gap-x-4">
                <dt className="text-gray-500">Certificat</dt>
                <dd className="text-gray-700 text-end">
                  <p>
                    {siteInfo.certificate?.issuer?.O} (
                    {siteInfo.certificate?.issuer?.CN})
                  </p>
                  <p className="text-xs text-gray-600">
                    Date d'expiration : {siteInfo.certificate?.valid_to}
                  </p>
                </dd>
              </div>
              <div className="flex justify-between py-3 gap-x-4">
                <dt className="text-gray-500">Délai de réponse (sec)</dt>
                <dd className="text-gray-700">{siteInfo.secSpent}</dd>
              </div>
            </>
          )}
        </dl>
      )}
    </>
  );
}

export default SiteInfo;
