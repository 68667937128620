// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/analytics";
import "firebase/compat/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB_TWgEAKv_To8SfcPy_-aKXOe528Y802E",
  authDomain: "bf-digital-40490.firebaseapp.com",
  projectId: "bf-digital-40490",
  storageBucket: "bf-digital-40490.appspot.com",
  messagingSenderId: "964681531229",
  appId: "1:964681531229:web:9be21515cc16fabd24f2b4"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics(app);
const auth = firebase.auth(app);
const functions = firebase.functions(app);
auth.languageCode = "fr";
auth.useDeviceLanguage();
// window.recaptchaVerifier = new RecaptchaVerifier(
//   document.getElementsByClassName("firebaseui-recaptcha-container"),
//   {
//     size: "invisible",
//     callback: (response) => {
//       // reCAPTCHA solved, allow signInWithPhoneNumber.
//       //onSignInSubmit();
//     },
//   },
//   app.auth()
// );
const db = firebase.firestore(app);

export { app, auth, db, analytics, functions };
